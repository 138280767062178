exports.components = {
  "component---src-layouts-post-layout-js-content-file-path-content-posts-airpods-dirty-secret-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/airpods-dirty-secret/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-airpods-dirty-secret-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-clear-airpods-pro-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/clear-airpods-pro/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-clear-airpods-pro-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-epfl-mobile-robotics-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/epfl_mobile_robotics/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-epfl-mobile-robotics-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-free-airpods-parts-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/free-airpods-parts/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-free-airpods-parts-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-galaxy-fold-car-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/galaxy_fold_car/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-galaxy-fold-car-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-iphone-usbc-mod-part-1-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/iphone_usbc_mod_part_1/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-iphone-usbc-mod-part-1-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-london-food-story-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/london_food_story/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-london-food-story-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-metal-sheet-bending-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/metal_sheet_bending/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-metal-sheet-bending-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-no-tool-stool-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/no_tool_stool/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-no-tool-stool-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-selling-first-usbc-iphone-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/selling-first-usbc-iphone/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-selling-first-usbc-iphone-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-stolen-airpods-pro-design-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/stolen-airpods-pro-design/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-stolen-airpods-pro-design-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-understanding-pytorch-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/understanding_pytorch/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-understanding-pytorch-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-worlds-first-lightning-android-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/worlds-first-lightning-android/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-worlds-first-lightning-android-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-posts-worlds-first-usbc-iphone-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/posts/worlds-first-usbc-iphone/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-posts-worlds-first-usbc-iphone-index-mdx" */),
  "component---src-layouts-post-layout-js-content-file-path-content-travel-cairo-luxor-train-index-mdx": () => import("./../../../src/layouts/post-layout.js?__contentFilePath=/opt/build/repo/content/travel/cairo_luxor_train/index.mdx" /* webpackChunkName: "component---src-layouts-post-layout-js-content-file-path-content-travel-cairo-luxor-train-index-mdx" */),
  "component---src-layouts-redirect-js": () => import("./../../../src/layouts/redirect.js" /* webpackChunkName: "component---src-layouts-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-experiments-js": () => import("./../../../src/pages/experiments.js" /* webpackChunkName: "component---src-pages-experiments-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-press-mdx": () => import("./../../../src/pages/press.mdx" /* webpackChunkName: "component---src-pages-press-mdx" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-speaking-js": () => import("./../../../src/pages/speaking.js" /* webpackChunkName: "component---src-pages-speaking-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

